import { Snackbar, Slide } from '@material-ui/core';
import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';

const ToastContext = React.createContext({});
ToastContext.displayName = 'ToastContext';

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert ref={ref} elevation={6} variant="filled" {...props} />;
});
Alert.displayName = 'ToastAlert';

const SlideTransition = React.forwardRef((props, ref) => {
  return <Slide ref={ref} {...props} direction="left" />;
});
SlideTransition.displayName = 'AlertTransition';

function ToastProvider({ children, defaultDuration = 5000 }) {
  const [toast, setToast] = React.useState(undefined);

  const error = React.useCallback((message) => setToast({ type: 'error', message }), [setToast]);
  const info = React.useCallback((message) => setToast({ type: 'info', message }), [setToast]);
  const success = React.useCallback((message) => setToast({ type: 'success', message }), [setToast]);
  const warning = React.useCallback((message) => setToast({ type: 'warning', message }), [setToast]);

  const value = React.useMemo(
    () => ({ error, info, setToast, success, warning }),
    [error, info, setToast, success, warning],
  );

  const handleClose = () => setToast(undefined);

  React.useEffect(() => {
    let timeoutHandler;
    if (toast) {
      timeoutHandler = setTimeout(() => {
        setToast(undefined);
      }, defaultDuration);
    }

    return () => {
      if (timeoutHandler) {
        clearTimeout(timeoutHandler);
      }
    };
  }, [toast, defaultDuration]);

  const isOpen = typeof toast !== 'undefined';
  const snackbarRef = React.createRef();
  return (
    <ToastContext.Provider value={value}>
      {children}
      <Snackbar
          ref={snackbarRef}
          autoHideDuration={defaultDuration}
          onClose={handleClose}
          open={isOpen}
          TransitionComponent={SlideTransition}
      >
        {toast && (
          <Alert onClose={handleClose} severity={toast.type}>
            {toast.message}
          </Alert>
        )}
      </Snackbar>
    </ToastContext.Provider>
  );
}

function useToast() {
  const context = React.useContext(ToastContext);
  if (context === undefined) {
    throw new Error(`useToast must be used within an ToastProvider`);
  }

  return context;
}

export { ToastProvider, useToast };
