// pages/_app.js
import {
  StylesProvider as MaterialUiStylesProvider,
  ThemeProvider as MaterialUiThemeProvider,
} from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import '../../styles/globals.css'
import { SessionProvider } from "next-auth/react"
//import AppLayout from "../components/layout";
import { light, materialUiTheme } from '../lib/themes';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import dynamic from 'next/dynamic';
import axios from 'axios';
import qs from 'qs';
import {ToastProvider} from "../components/shared/toast";

const AppLayout = dynamic(() => import('../components/layout'), { ssr: false });

axios.defaults.paramsSerializer = (params) => (
  qs.stringify(params, { indices: false }) // param=value1&param=value2 instead of param[]=value1&param[]=value2
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      //staleTime: 30000
    }
  }
});
/*
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry(failureCount, error: AxiosError) {
        if (error.response.status === 404) {
          return false;
        }

        return failureCount < 2;
      },
      useErrorBoundary: false,
    },
    mutations: {
      retry(failureCount, error: AxiosError) {
        if (error.response.status === 404) {
          return false;
        }

        return failureCount < 2;
      },
      useErrorBoundary: false,
    },
  },
});
 */

export default function App({ Component, pageProps: { session, ...pageProps } }) {
  return (
    <SessionProvider session={session}>
      <QueryClientProvider client={queryClient}>
        <MaterialUiThemeProvider theme={materialUiTheme}>
          <MaterialUiStylesProvider injectFirst>
              <ThemeProvider theme={light}>
                <ToastProvider>
                  <AppLayout>
                    <Component {...pageProps} />
                  </AppLayout>
                </ToastProvider>
              </ThemeProvider>
          </MaterialUiStylesProvider>
        </MaterialUiThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </SessionProvider>
  )
}

