import { createTheme } from '@material-ui/core/styles';

const light = {
  breakpoints: {
    xs: 320,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
  colors: {
    black: '#141414',
    blue: '#2a3574',
    red: '#2a3574',
    text: {
      primary: '#000',
      secondary: '#222',
    },
    yellow: '#fccc04',
    white: '#ffffff',
  }
};

const materialUiTheme = createTheme({
  palette: {
    primary: {
      main: light.colors.blue,
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
  spacing: 8,
  typography: {
    fontSize: 12,
  },
});

export { light, materialUiTheme };
